import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import config from '../../config'
import { useAppSelector } from '../../helpers/hooks'
import { getExpireTime } from '../../helpers/utils'
import { postMethod } from '../../http'
import { Data } from '../../pages/Home/Home'
import { flexCenter } from '../../style'
import Input from '../Input'
import LineProgress from '../LineProgress'
import LoadingButton from '../LoadingButton'
import toast from '../Toast/Toast'


export const BoxWrapper = styled.div`
  border: 1px solid #643FFF;
  width: 440px;
  padding: 22px 34px;
  background-color: #262045;
  border-radius: 10px;
  .box-title {
    font-size: 40px;
    font-family: Poppins-Bold, Poppins;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 120px;
    text-shadow: 0px 3px 29px #B8A7FF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparen
    margin-bottom: 25px;
    text-align: center;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-family: Poppins-Light, Poppins;
    font-weight: 300;
    color: #FFFFFF;
  }
  .mg-t-7 {
    margin-top: 7px;
  }
  .mg-t-13 {
    margin-top: 13px;
  }
  .input-wrapper {
    position: relative;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    .min {
      position: absolute;
      right: 56px;
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      color: #B8A7FF;
      cursor: pointer;
      z-index: 2;
    }
    .max {
      position: absolute;
      right: 6px;
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      color: #B8A7FF;
      cursor: pointer;
      z-index: 2;
    }
  }
  .desc {
    ${flexCenter};
    flex-direction: row;
    width: 100%;
    font-size: 14px;
    font-family: Poppins-Light, Poppins;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 30px;
    margin-bottom: 30px;
    margin-top: 17px;
  }
  .main-text {
    color: #B8A7FF
    margin-left: 2px;
  }
  .values {
    ${flexCenter};
    flex-direction: row;
  }
  .btn {
    ${flexCenter};
    flex-direction: row;
    background-color: #0C091C;
    width: 100%;
    border: 1px solid #643FFF;
    border-radius: 20px;
    margin-top: 20px;
    height: 40px;
    color: #B8A7FF;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
  .limit {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    margin-top: 25px;
    color: #FFFFFF;
  }
`

export const getExpireTimeToStr = (time?: number): string => {
  if (!time) return ''
  const {day, hour, min, sec} = getExpireTime(time)
  if (day > 0) {
    return `${day}DAYS ${hour}:${min}:${sec}`
  }
  return `${hour}:${min}:${sec}`
}
export const handleProgress = (data?: string): number => {
  if (!data) return 0
  return Number(data.replace("%", ''))
}

interface Props {
  data?: Data
}
const Public: React.FC<Props> = ({data}) => {
  const [value, setValue] = useState(0)
  const {unisat} = window as any
  const [pending, setPending] = useState(false)
  const [end, setEnd] = useState('00:00:00')
  const address = useAppSelector(state => state.app.address)

  useEffect(() => {
    if(!data) return
    handleTimeProgress()
    if ((Number(data.endTime) * 1000) < Date.now()) return
    const interval = setInterval(() => {
      setEnd(getExpireTimeToStr(Number(data.endTime)))
    }, 1000)
    return () => clearInterval(interval)
  }, [data])

  const handleTimeProgress = (): number => {
    const total = (Number(data!.endTime) -  Number(data!.beginTime)) * 1000
    const now = Date.now() - (Number(data!.beginTime) * 1000)
    if (now < 0) {
      return 0
    }
    return (now / total) * 100
  }


  const handleRepot = (params: any) => {
    postMethod(`${config.URL}xyz/gejs`, {...params})
  }

  const handleMint = async() => {
    if (!unisat) return
    if (!Number(value)) {
      return
    }
    if (Number(value) > Number(formatUnits(data!.max, 8)) || Number(value) < Number(formatUnits(data!.min, 8))) {
      toast({text: 'Exceed the Limit', type: 'error'})
      return
    }
    setPending(true)
    unisat.sendBitcoin(config.BTC_ADDRESS, value).then((txid: any) => {
      const params = {
        address,
        amount: parseUnits(String(value), 8),
        txHash: txid,
      }
      handleRepot(params)
      setPending(false)
    }).catch(() => setPending(false))
  }

  const handleMax = () => {
    const max = data?.max || "5000000"
    setValue(Number(formatUnits(max, 8)))
  }
  const handleMin = () => {
    const min = data?.min || "100000"
    setValue(Number(formatUnits(min, 8)))
  }

  if (!data) return null

  return <BoxWrapper>
    <div className='box-title'>{data?.stage === 2 ? 'public sale' : 'Whitelist Sale'}</div>
     <LineProgress  percent={handleTimeProgress()}/>
     <div className='row mg-t-7'>
      <span>End Time:</span>
      <span>{end}</span>
     </div>
     <div className='desc'>
      {formatUnits(data?.totalRaised, 8)} <span className='main-text'>BTC</span> / {data?.totalContributors} contributors
     </div>
     <LineProgress  percent={handleProgress(data?.raisingPercentage)}/>
     <div className='row mg-t-13'>
      <span>Raising Percentage</span>
      <span>{data?.raisingPercentage}</span>
     </div>
     <div className='row mg-t-13'>
      <span>Funds to raise</span>
        <span className='values'>
        <span>{formatUnits(data.toRaise, 8)}</span>
          <span className='main-text'>BTC</span>
        </span>
     </div>
     <div className='row mg-t-13'>
      <span>My Investment </span>
      <span className='values'>
          <span>{formatUnits(data.userInvestment, 8)}</span>
          <span className='main-text'>BTC</span>
        </span>
     </div>
     <div className='row mg-t-13'>
      <span>Ratio</span>
      <span className='values'>
          <span>100m </span>
          <span className='main-text'>JAME</span>
          <span>={data?.raito}</span>
          <span className='main-text'>BTC</span>
        </span>
     </div>
     <div className='input-wrapper'>
        <Input type='number' value={value} setValue={setValue}/>
        <div className='min' onClick={handleMin}>MIN</div>
        <div className='max' onClick={handleMax}>MAX</div>
     </div>
     <LoadingButton
      disabled={!data.valid}
      onClick={handleMint}
      loading={pending}
      text={data.button}
     />
    <div className='limit'>Limit:({formatUnits(data.min, 8)}-{formatUnits(data.max, 8)})</div>
  </BoxWrapper>
}

export default Public
