import { BigNumber, utils } from 'ethers'
import VConsole from 'vconsole'

export const shortenStr = (str: string, front = 6, behind = 4): string => {
  return `${str.substring(0, front)}...${str.substring(str.length - behind)}`
}

// 10进制转换16进制
export const decimalToHex = (decimal: number) => {
  return `0x${decimal.toString(16)}`
}

export const DESKTOP_WIDTH = 768
export let isDesktop = false
if (typeof document !== 'undefined') {
  if (window.innerWidth >= DESKTOP_WIDTH) {
    isDesktop = true
  }
}
// 用于H5 端调试
// e.g. vConsole.log.log(xxx)
export const vConsole = isDesktop ? null : new VConsole()

export const jsonToQuery = (json: any) => {
  return Object.keys(json)
    .map((key: any) => {
      return `${key}=${json[key]}`
    })
    .join('&')
}

export const queryToJson = (str: string) => {
  if (!str) { return {} }
  return str.split('&').reduce((target, next) => {
      const nextArr = next.split('=')
      // eslint-disable-next-line no-param-reassign
      target[nextArr[0]] = nextArr[1] || ''
      return target
    }, {} as Record<string, string>)
}
// ether to wai
export const parseEther = (value: number): BigNumber => {
  return utils.parseEther(String(value))
}
// wai to ether
export const formatEther = (balance: BigNumber): number => {
  return Number(utils.formatEther(balance))
}
// limit decimal places
export const toFloor = (num: number, decimal = 4): string => {
  const precision = Math.pow(10, decimal)
  const result =  String(Math.floor(Number((num * precision).toFixed(1))) / precision)
  const resArr = String(result).split('.')
  if (resArr[1]) {
    if (resArr[1].length !== decimal) {
      return `${resArr[0]}.${resArr[1].padEnd(decimal, '0')}`
    }
    return result.toString()
  } else {
    return `${resArr[0]}.${''.padEnd(decimal, '0')}`
  }
}

export const getExpireTime = (timestamp: number) => {
  const now = Date.now()
  const diff = timestamp * 1000 - now
  const handleZero = (num: number) => {
    if (num >= 10) return num
    return `0${num}`
  }
  return {
    day: handleZero(Math.floor(diff / (60 * 60 * 24 * 1000))),
    hour: handleZero(Math.floor((diff / (60 * 60 * 1000)) % 24)),
    min: handleZero(Math.floor((diff / (60 * 1000)) % 60)),
    sec: handleZero(Math.floor((diff / 1000) % 60)),
  }
}
// get within the scope of random Numbers
export const random = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) === min  ? (min + 1) : Math.floor(Math.random() * (max - min)) + min
}
