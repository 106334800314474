import { Line } from 'rc-progress'

interface Props {
  percent: number
}
const LineProgress: React.FC<Props> = ({percent}) => {

  return (
    <Line style={{height: '14px', borderRadius: '7px'}} percent={percent}  strokeColor="#643FFF" trailColor="#B8A7FF"/>
  )
}

export default LineProgress
