import { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../../helpers/hooks'

import { defaultTheme, flexCenter } from '../../style'
import Wallet from '../Wallet'
import LOGO from '../../images/logo.png'
import LightIcon from './light-icon.svg'
import Unisat from '../Unisat'
import Tab from '../Tab'

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  z-index: 10;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${defaultTheme.isDesktop ? '20px 160px' : '0 0 0 20px'};
  background-color: ${props => props.theme.bgColor};
  border-bottom: 1px solid ${props => props.theme.borderColor};
  @media (max-width: 1440px){
    padding: 20px 60px;
  }
  .row {
    ${flexCenter};
    flex-direction: row;
  }
  .icon {
    cursor:  ${prop => prop.theme.isDesktop ? 'pointer' : 'none'};
    border-radius: 8px;
    width: 24px;
    height: 24px;
    margin: 0 24px;
    :hover {
      background-color: ${props => (props.theme.isDesktop ? props.theme.primaryColor : null)};
      opacity:  ${prop => prop.theme.isDesktop ? '0.2' : '1'};
    }
  }
`
const LogoWrapper = styled.div`
  ${flexCenter};
  flex-direction: row;
  align-items: center;
  color: #FFFFFF;
  font-size: ${defaultTheme.fontLarge};
  font-weight: bold;
  cursor:  ${prop => prop.theme.isDesktop ? 'pointer' : 'none'};
  font-family: MiSans-Bold;
  .logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`

const Header: React.FC = () => {

  return (
    <HeaderWrapper>
      <LogoWrapper >
        <img src={LOGO} className="logo" />
        <span>Edohigan Network</span>
      </LogoWrapper>
      <Tab />
      <Unisat />
    </HeaderWrapper>
  )
}

export default Header
