import { useEffect, useState } from "react"
import styled from "styled-components"
import Public from "../../components/Public"
import config from "../../config"
import { useAppSelector } from "../../helpers/hooks"
import { postMethod } from "../../http"
import { flexCenter } from "../../style"
import { Data } from "../Home/Home"

const Wrapper = styled.div`
  ${flexCenter};
  margin-top: 218px;
  margin-bottom: 256px;
`


const IDO: React.FC = () => {
  const [data, setData] = useState<Data>()
  const address = useAppSelector(state => state.app.address) || ''

  useEffect(()=> {
    getData()
  }, [address])

  const getData = () => {
    postMethod(`${config.URL}xyz/phlk`, {address}).then(res => {
      setData(res.data)
    })
  }

  return <Wrapper>
    <Public data={data}/>
  </Wrapper>
}

export default IDO
