import { Route, Routes, useRoutes } from 'react-router-dom'
import { LoadingProvider } from './components/Loading/Loading'
import ToastContainer from './components/Toast/ToastContainer'
import Header from './components/Header'
import MessageContainer from './components/Message/MessageContainer'
import { ThemeProvider } from './ThemeProvider'
import routes from './routerConfig'
import Footer from './components/Footer'

function App() {
  return (
    <ThemeProvider>
      <LoadingProvider>
        <Header />
        {useRoutes(routes)}
        <Footer />
        <ToastContainer />
        <MessageContainer />
      </LoadingProvider>
    </ThemeProvider>
  )
}

export default App
