import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useCurrentPath } from '../../helpers/hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
  margin-left: 60px;
  .item {
    font-size: 20px;
    color: #FFFFFF;
    line-height: 0px;
    margin-right: 60px;
    text-transform: uppercase;
    font-weight: 300;
    @media (max-width: 1320px)and (min-width: 768px){
      margin-right: 30px;
    }
  }
  .current {
    font-weight: bold;
  }
`

const Tab: React.FC = () => {
  const currentPath = useCurrentPath()

  return <Wrapper>
    <Link to="/">
      <div className={currentPath === '/' ? 'item current' : 'item'}>Home</div>
    </Link>
    <a href="" className="item" target="_blank" rel="noreferrer">Features</a>
    <a href="" className="item" target="_blank" rel="noreferrer">Business logic</a>
    <a href="" className="item" target="_blank" rel="noreferrer">Token distribution</a>
    <Link to="/ido">
      <div className={currentPath === '/ido' ? 'item current' : 'item'}>IDO</div>
    </Link>

  </Wrapper>
}

export default Tab
