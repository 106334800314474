import { LoadingButton as MLoadingButton } from '@mui/lab'
import styled from 'styled-components'
import { defaultTheme, flexCenter } from '../../style'

const ButtonWrapper = styled(MLoadingButton)`
  &.MuiButton-contained {
    ${flexCenter};
    flex-direction: row;
    background-color: #0C091C;
    width: 100%;
    border: 1px solid #643FFF;
    border-radius: 20px;
    margin-top: 20px;
    height: 40px;
    color: #B8A7FF;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    :hover {

    }
    &.Mui-disabled {
      opacity: 0.8;
      cursor: not-allowed;
      pointer-events: auto;
      padding-left: ${props => props.loading && '40px'};
    }
    &.small {
      line-height: 24px;
      font-size: ${defaultTheme.fontSmall};
      padding: 8px 16px;
      border-radius: 16px;
    }
    &.large {
      line-height: 24px;
      font-size: ${defaultTheme.fontLarge};
      padding: 16px 48px;
      border-radius: 32px;
    }
  }
  &.MuiButton-outlined {
    background-color: ${props => props.theme.grey1};
    color: ${props => props.theme.grey1};
    border-color: ${props => props.theme.grey1};
    border-radius: 180px;
    font-weight: 400;
    text-transform: none;
    font-size: ${defaultTheme.fontNormal};
    padding: 8px 24px;
  }
  &.MuiButton-text {
    border: 0;
  }
`

type LoadingButtonProps = {
  loading?: boolean
  disabled?: boolean
  text: string
  size?: 'small' | 'large' | 'medium'
  variant?: 'text' | 'outlined' | 'contained' // 文字型、描边型、实心按钮
  fullWidth?: boolean // 是否根据父元素填充宽度
  loadingPosition?: 'center' | 'end' | 'start'
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  disabled,
  text,
  size = 'medium',
  variant = 'contained',
  loadingPosition = 'start',
  fullWidth = false,
  onClick,
}) => {
  const handleClick = (evt: any) => {
    if (onClick && !disabled) {
      onClick(evt)
    }
  }

  return (
    <ButtonWrapper
      className={size}
      disabled={disabled}
      loading={loading}
      variant={variant}
      onClick={handleClick}
      size={size}
      loadingPosition={loadingPosition}
      fullWidth={fullWidth}
    >
      {text}
    </ButtonWrapper>
  )
}

export default LoadingButton
