import styled from 'styled-components'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

type InputProps = React.ComponentProps<'input'>

export const BaseInput = styled.input`
  width: 100%;
  background-color: #0C091C;
  border: 1px solid #643FFF;
  line-height: 20px;
  font-size: 14px;
  font-weight: 300;
  color: #FFFFFF;
  :focus-visible {
    outline: none;
  }
`

export const NumberInput = styled(NumericFormat)`
  width: 100%;
  background-color: #0C091C;
  border:0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 16px;
  border-radius: 20px;
  color: #FFFFFF;
  :focus-visible {
    outline: none;
    -webkit-appearance: none; /*去除系统默认的样式*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 点击高亮的颜色*/
  }
`

const MAX_LIMIT = 10 ** 18
const LIMIT = 18

type Iprop = {
  value: any
  decimalScale?: NumericFormatProps['decimalScale']
  allowNegative?: NumericFormatProps['allowNegative']
  setValue: (value: any) => void
} & InputProps
const Input: React.FC<Iprop> = prop => {
  const {
    setValue,
    type = 'text',
    placeholder,
    value,
    decimalScale = 18,
    disabled,
    allowNegative = false,
  } = prop
  return (
    <>
      {type === 'number' ? (
        <NumberInput
          allowLeadingZeros={false}
          decimalScale={decimalScale}
          allowNegative={allowNegative}
          disabled={disabled}
          value={value}
          onValueChange={(values: { value: any }) => {
            if (values.value.length < LIMIT) {
              setValue(values.value)
            } else {
              setValue(Number(values.value.substring(0, LIMIT)))
            }
          }}
          isAllowed={(values: { value: any }) => {
            return Number(values.value) < MAX_LIMIT
          }}
          placeholder={placeholder}
        />
      ) : (
        <BaseInput
          value={value}
          type="text"
          disabled={disabled}
          onChange={e => {
            setValue(e.target.value)
          }}
          placeholder={placeholder}
        />
      )}
    </>
  )
}

export default Input
