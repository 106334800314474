import React from 'react'
import { RouteObject } from 'react-router-dom'
import Home from './pages/Home'
import IDO from './pages/IDO'

export const routes: RouteObject[] = [
  { path: '/', element: <Home /> },
  { path: '/ido', element: <IDO /> },
  { path: '*', element: <Home /> },
]
export default routes
