import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Button from "../../components/Button"
import Public from "../../components/Public"
import config from "../../config"
import { useAppSelector } from "../../helpers/hooks"
import { postMethod } from "../../http"
import MAIN_IMG from "./main.png"

const Wrapper = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #FFFFFF;
  font-family: Poppins-Bold, Poppins;
  .main-img {
    width: 624px;
    height: 624px;
  }
  .title {
    font-size: 60px;
    line-height: 120px;
    text-shadow: 0px 3px 29px #B8A7FF;
    font-weight: bold;
    text-align: center;
    margin-top: -120px;
  }
  .desc {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
  }
  .btns {
    margin-top: 54px;
    margin-bottom: 118px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    button + button {
      margin-left: 40px;
    }
  }
  .box {
    margin-top: 125px;
    margin-bottom: 94px;
  }
`

export interface Data {
  totalRaised: string
  toRaise: string
  totalContributors: string
  raisingPercentage: string
  userInvestment: string
  raito: string
  beginTime: string
  endTime: string
  stage: number
  min: string
  max: string
  valid: boolean
  button: string
}

const Home: React.FC = () => {
  const [data, setData] = useState<Data>()
  const address = useAppSelector(state => state.app.address) || ''

  useEffect(()=> {
    getData()
  }, [address])

  const getData = () => {
    postMethod(`${config.URL}xyz/phlk`, {address}).then(res => {
      setData(res.data)
    })
  }

  return <Wrapper>
    <img src={MAIN_IMG} className="main-img" />
    <div className="title">
      JAME - the first AMM DEX<br /> in the BRC20 ecosystem!
    </div>
    <div className="desc" >JAME is a BRC20 token initiated by Edohigan, which is awarded to all users who participate in the <br />
    edohigan coin stake, check your whitelist eligibility to receive JAME, or participate in Project IDO and <br />
    experience the new brc20 gameplay together</div>
    <div className="btns">
      <Link to="/ido">
        <Button text={data?.stage === 2 ? 'IDO' : 'whitelist'} />
      </Link>
    </div>
    <Public data={data}/>
  </Wrapper>
}

export default Home


