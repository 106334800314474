import styled from 'styled-components'
import LOGO from '../../images/logo.png'
import DS from "./ds.png"
import TW from "./tw.png"
import TL from "./tl.png"


const Wrapper = styled.div`
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color:#130F24;
  margin: 80px 170px 110px 170px;
  padding-top: 177px;
  padding-bottom: 70px;
  font-size: 20px;
  color: #fff;
  .logo {
    width: 110px;
    height: 110px;
    margin-bottom: 43px;
  }
  .links {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 76px;
    .item {
      font-size: 20px;
      cursor: pointer;
      margin: 0 35px;
    }
  }
  .icons {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 66px;
    .item {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 0 20px;
    }
  }
  .text {
    position: absolute;
    bottom: -40px;
    left: calc(50% - 145px);
    font-size: 20px;
    font-weight: 300;
    color: #FFFFFF;
  }
  .links-rows {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-weight: 300;
    color: #FFFFFF;
    font-size: 20px;
    margin-top: 60px;
  }

`

const Footer: React.FC = () => {
  return <Wrapper >
    <img src={LOGO} className="logo" />
    <span>Edohigan Network</span>
    <div className='links'>
      <a href="" className="item" target="_blank" rel="noreferrer">Home</a>
      <a href="" className="item" target="_blank" rel="noreferrer">Blog</a>
      <a href="" className="item" target="_blank" rel="noreferrer">Security</a>
      <a href="" className="item" target="_blank" rel="noreferrer">Jobs</a>
      <a href="" className="item" target="_blank" rel="noreferrer">Docs</a>
    </div>
    <div className='links-rows'>
      <div className='link'>Graph Foundation</div>
      <div className='link'>Privacy Policy</div>
      <div className='link'>Forum</div>
      <div className='link'>Terms of Service</div>
      <div className='link'>Testnet</div>
      <div className='link'>Partnership Requests</div>
    </div>
    <div className='icons'>
      <a href="https://discord.gg/C8Fqem6KaQ" target="_blank" rel="noreferrer">
        <img src={DS} className="item"/>
      </a>
      <a href="" target="_blank" rel="noreferrer">
        <img src={TL} className="item"/>
      </a>
      <a href="https://twitter.com/Edohigan_NW" target="_blank" rel="noreferrer">
        <img src={TW} className="item"/>
      </a>
    </div>
    <div className='text'>copyright©Edohigan Network</div>
  </Wrapper>
}

export default Footer
