/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import { isDesktop } from '../helpers/utils'
import { DEFAULT_FONT_SIZE } from '../style'
import { Wallet } from '../web3/connectors'

interface AppState {
  selectedWallet?: Wallet
  isDesktop: boolean
  lastTimestamp: number
  address: string
}

const handleView = (desktopValue: boolean) => {
   // 如果是尺寸的设计稿在这里修改
   const WIDTH = 750
   const IPHONE = 375
    // 设置html标签的fontSize
    document.documentElement.style.fontSize = desktopValue ? DEFAULT_FONT_SIZE : `${100 * IPHONE / WIDTH}px`
}

const initialState: AppState = {
  selectedWallet: undefined,
  isDesktop,
  lastTimestamp: 0,
  address: ''
}
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateSelectedWallet(state, { payload: { wallet } }) {
      state.selectedWallet = wallet
      state.lastTimestamp = new Date().getTime()
    },
    updateDesktop(state, { payload: { desktopValue } }) {
      handleView(desktopValue)
      state.isDesktop = desktopValue
    },
    updateAddress(state, { payload: { address } }) {
      state.address = address
    },
  },

})

export const {
  updateSelectedWallet,
  updateDesktop,
  updateAddress,
} = appSlice.actions
export default appSlice.reducer
