import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../../helpers/hooks'
import { shortenStr } from '../../helpers/utils'
import { updateAddress } from '../../redux/reducer'

const Wrapper = styled.div`
  border: 1px solid #643FFF;
  padding: 10px 25px;
  background-color: #262045;
  border-radius: 25px;
  font-size: 20px;
  font-family: Poppins-Light, Poppins;
  font-weight: 300;
  color: #FFFFFF;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
`
const CHROME_URL = 'https://chrome.google.com/webstore/detail/unisat-wallet/ppbibelpcjmhbdihakflkdcoccbgbkpo'
const Unisat: React.FC = () => {
  const [unisatInstalled, setUnisatInstalled] = useState(false)
  const [connected, setConnected] = useState(false)
  const [address, setAddress] = useState("")
  const dispatch = useAppDispatch()
  const selfRef = useRef<{ accounts: string[] }>({
    accounts: [],
  })
  const self = selfRef.current
  const {unisat} = window as any


  useEffect(() => {
    if (unisat) {
      setUnisatInstalled(true)
      unisat.requestAccounts()
    } else {
      setUnisatInstalled(false)
      return
    }

    unisat.getAccounts().then((data: string[]) => {
      handleAccountsChanged(data)
    })

    unisat.on("accountsChanged", handleAccountsChanged)
    unisat.on("networkChanged", handleNetworkChanged)

    return () => {
      unisat.removeListener("accountsChanged", handleAccountsChanged)
      unisat.removeListener("networkChanged", handleNetworkChanged)
    }
  }, [unisat])

  const handleConnect = () => {
    if (unisatInstalled) {
      unisat.requestAccounts()
    } else {
      window.open(CHROME_URL)
    }
  }
  const handleAccountsChanged = (_accounts: string[]) => {
    if (self.accounts[0] === _accounts[0]) {
      // prevent from triggering twice
      return
    }
    self.accounts = _accounts
    if (_accounts.length > 0) {
      setConnected(true)

      setAddress(_accounts[0])
      dispatch(updateAddress({ address: _accounts[0] }))
      getBasicInfo()
    } else {
      setConnected(false)
    }
  }

  const handleNetworkChanged = (data: string) => {
    getBasicInfo()
  }
  const getBasicInfo = async () => {
    const [adds] = await unisat.getAccounts()
    setAddress(adds)
  }
  if (connected && address) {
    return <Wrapper>
    {shortenStr(address)}
  </Wrapper>
  }
  return <Wrapper onClick={handleConnect}>
    connect wallet
  </Wrapper>
}

export default Unisat
